import React from 'react'
import fairchild from '../images/CFlogo.png'
import MothButtons from '../components/MothButtons'
import homeBG from '../images/home_background.jpg'
import HomeBackground from '../components/homeBackground'
import SEO from '../components/seo'
import "./layout.css"

export default function home() {

    return (
        <>
        <SEO title="Home" />
        <HomeBackground>
            <img src={homeBG} style={{display: "none"}}/>
            <div className="home-content">
                <div className="home-title">
                    <img src={fairchild} />
                </div>
                <MothButtons size="large" order={[
                    {to: "/curriculum-vitae", label: "Curriculum Vitae"},
                    {to: "/portfolio", label:  "Portfolio"},
                    {to: "/links", label:  "Links"},
                    {to: "/contact", label:  "Contact"},
                    ]}/>
            </div>
        </HomeBackground>
        </>
    )
}
